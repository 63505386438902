import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  CardContent,
  Chip,
  IconButton,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  LinearProgress,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Avatar,
  CardActionArea,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState, useEffect } from "react";
import {
  Email,
  Phone,
  AccountBalance,
  Add as AddIcon,
  ArrowCircleDown,
  Save as SaveIcon,
  Close as CloseIcon,
  Person,
  Business,
} from "@mui/icons-material";
import { TbCrane } from "react-icons/tb";
import { MoreVert } from "@material-ui/icons";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import FormBox from "../../styled/generic/FormBox";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getProfileByWallet } from "../commonComponent/transaction/api";
import GeneralImportComponent from "../../global/GeneralImportComponent";
import { getProfileDataPopulate } from "../../team/procurements/api.call";

const BiilingAccounts = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenImportModel, setIsOpenImportModel] = useState(false);
  const { walletId } = useParams();
  const dispatch = useDispatch();
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchBillingAccounts = async () => {
    const response = await Api.post(
      `wallet/billing/billing-account/sharedIds/get`,
      {
        sharedIds: [profile._id],
      }
    );
    if (response) {
      setBillingAccounts(response.billingAccounts);
    } else {
      console.log("Error fetching billing accounts");
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error fetching billing accounts",
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (profile) {
      fetchBillingAccounts();
    }
  }, [profile]);

  useEffect(() => {
    if (walletId) {
      getProfileByWallet({ walletId })
        .then((id) => {
          getProfileDataPopulate({ profileId: id }).then((profile) => {
            setProfile(profile);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [walletId]);

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Box sx={{ p: 2, pb: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Billing Accounts</Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => setIsOpen(true)}
            >
              Billing Accounts
            </Button>
            <Button
              onClick={() => setIsOpenImportModel(true)}
              variant="contained"
              startIcon={<ArrowCircleDown />}
            >
              Import
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />

      {loading ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ flex: 1, overflowY: "auto", px: 2, pb: 10 }}>
          <Grid container>
            {billingAccounts.map((account) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={account._id}>
                <BillingAccountCard billingAccount={account} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <SaveBillingAccoutModel
        fetchBillingAccounts={fetchBillingAccounts}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        walletId={walletId}
        profile={profile}
      />
      <ImportBillingAccount
        setIsOpen={setIsOpenImportModel}
        isOpen={isOpenImportModel}
        walletId={walletId}
        profile={profile}
        fetchBillingAccounts={fetchBillingAccounts}
      />
    </Box>
  );
};

export default BiilingAccounts;

const BillingAccountCard = ({ billingAccount }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Card
        elevation={2}
        variant="outlined"
        sx={{
          margin: 1,
        }}
      >
        <CardContent
          sx={{
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              color={"primary"}
            >
              {billingAccount.name}
            </Typography>
            <IconButton size="small">
              <MoreVert />
            </IconButton>
          </Box>
          <Divider
            sx={{
              my: 1,
              mb: 2,
            }}
          />
          <Box display="flex" alignItems="center" mb={1}>
            <Email fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary" ml={1}>
              {billingAccount.email}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <Phone fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary" ml={1}>
              {billingAccount.phone}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <AccountBalance fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary" ml={1}>
              {billingAccount.address.city}, {billingAccount.address.country}
            </Typography>
          </Box>
          <Box display="flex" alignItems={"center"} mb={2}>
            <AccountBalance fontSize="small" color="action" />
            {billingAccount.bankAccoun ? (
              <Typography variant="body2" color="text.secondary" ml={1}>
                {billingAccount.bankAccount?.bankName}(
                {billingAccount.bankAccount?.accountNumber})
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary" ml={1}>
                N/A
              </Typography>
            )}
          </Box>
          <Box display="flex" flexWrap="wrap" gap={0.5}>
            {billingAccount.providers.map((provider, index) => (
              <Chip
                key={index}
                label={provider}
                size="small"
                variant="outlined"
              />
            ))}
          </Box>{" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              mt: 2,
            }}
          >
            {/* <Button
              variant="contained"
              size="small"
              startIcon={<RemoveRedEyeIcon />}
              onClick={() => {
                // setIsOpen(true);
              }}
            >
              View
            </Button> */}
          </Box>
        </CardContent>
      </Card>
      <BillingAccountModel isOpen={isOpen} setIsOpen={setIsOpen} />{" "}
    </>
  );
};

const BillingAccountModel = ({ isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">Billing Account Details</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          Manage your billing account details to ensure accurate and up-to-date
          payment information. This includes keeping your payment methods,
          billing address, and account settings current to avoid interruptions
          in service.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const fetchBankAccounts = async (walletId) => {
  const response = await Api.post(
    `wallet/get/bank-accounts
`,
    {
      walletId,
    }
  );
  return response.data;
};

const SaveBillingAccoutModel = ({
  isOpen,
  setIsOpen,
  walletId,
  dispatch,
  profile,
  fetchBillingAccounts,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    panNumber: "",
    bankAccount: "",
    address: {
      line1: "",
      postal_code: "",
      city: "",
      state: "",
      country: "",
    },
    gstNumber: "",
    parentModelName: "",
  });

  useEffect(() => {
    if (!walletId && !isOpen) return console.log("No walletId found");
    fetchBankAccounts(walletId).then((data) => {
      setBankAccounts(data);
    });
  }, [walletId, isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: value,
      },
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      ...formData,
      parentModelName: profile.parentModelName,
      shared: [profile._id],
      wallet: walletId, // current wallet id
      user: user._id, // current  user profile id
    };

    const data = await Api.post(
      "/wallet/billing/billing-account/create",
      payload
    );
    if (data) {
      fetchBillingAccounts();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: data.message,
        },
      });
      resetForm();
      handleClose();
    } else {
      handleClose();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while creating billing account",
        },
      });
    }
    console.log(formData);
    setLoading(false);
  };

  const resetForm = () =>
    setFormData({
      name: "",
      email: "",
      panNumber: "",
      phone: "",
      address: {
        line1: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
      },
      gstNumber: "",
      parentModelName: "",
    });

  const handleClose = (e) => {
    e?.preventDefault();
    resetForm();
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="dialog-title">Create Billing Account</DialogTitle>
      <form onSubmit={handleSave} onReset={handleClose}>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              minWidth: "800px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <FormBox label="Name" disableMargins>
                <TextField
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Name"
                  required
                />
              </FormBox>
              <FormBox label="Email" disableMargins>
                <TextField
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Email"
                  required
                />
              </FormBox>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}
            >
              <FormBox label="Phone" disableMargins>
                <TextField
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Phone"
                  required
                />
              </FormBox>
              <FormBox label="GST Number" disableMargins>
                <TextField
                  name="gstNumber"
                  value={formData.gstNumber}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="GST Number"
                />
              </FormBox>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              }}
            >
              <FormBox label="Pan Number" disableMargins>
                <TextField
                  name="panNumber"
                  value={formData.panNumber}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Pan Number"
                />
              </FormBox>
              <FormBox label={"Bank account"} disableMargins>
                <FormControl fullWidth>
                  <InputLabel size="small" id="bank-account-select">
                    Bank Account
                  </InputLabel>
                  <Select
                    labelId="bank-account-select"
                    size="small"
                    name="bankAccount"
                    placeholder="Bank account"
                    label="Bank account"
                    value={formData.selectedBankAccount}
                    onChange={handleInputChange}
                    sx={{ minWidth: "120px" }}
                    fullWidth
                  >
                    <MenuItem value="">Select</MenuItem>
                    {bankAccounts?.map((item) => (
                      <MenuItem key={item?._id} value={item?._id}>
                        {item?.bankName || "Untitled"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormBox>
            </Box>

            <FormBox label="Address">
              <TextField
                name="line1"
                value={formData.address.line1}
                onChange={handleAddressChange}
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Address Line 1"
                sx={{ mb: 1 }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <TextField
                  name="city"
                  value={formData.address.city}
                  onChange={handleAddressChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="City"
                />
                <TextField
                  name="state"
                  value={formData.address.state}
                  onChange={handleAddressChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="State"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  mt: 1,
                }}
              >
                <TextField
                  name="postal_code"
                  value={formData.address.postal_code}
                  onChange={handleAddressChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Postal Code"
                />
                <TextField
                  name="country"
                  value={formData.address.country}
                  onChange={handleAddressChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Country"
                />
              </Box>
            </FormBox>
            {/* <FormBox label="Parent Model">
              <FormControl fullWidth size="small">
                <Select
                  name="parentModelName"
                  value={formData.parentModelName}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Project">Project</MenuItem>
                  <MenuItem value="Organization">Organization</MenuItem>
                  <MenuItem value="User">User</MenuItem>
                </Select>
              </FormControl>
            </FormBox> */}
          </Box>
        </DialogContent>
        {loading && <LinearProgress />}
        <DialogActions>
          <Button variant="outlined" type={"reset"}>
            Close
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            <span>Save</span>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ImportBillingAccount = ({
  isOpen,
  setIsOpen,
  walletId,
  dispatch,
  profile,
  fetchBillingAccounts,
}) => {
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [importIsOpen, setImportIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleImport = async () => {
    setLoading(true);
    const payload = {
      billingAccountsIds: [...billingAccounts.map((item) => item._id)],
      currentProfileId: profile._id,
    };
    const data = await Api.post(
      "wallet/billing/billing-account/import",
      payload
    );
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: data.message,
        },
      });
      setImportIsOpen(false);
      fetchBillingAccounts();
      setBillingAccounts([]);
      setIsOpen(false);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while importing billing accounts",
        },
      });
    }
    setLoading(false);
  };

  return (
    <DrawerContainer
      setOpen={setIsOpen}
      open={isOpen}
      onClose={setIsOpen}
      title={"Import Billing Account"}
    >
      <Box
        sx={{
          minWidth: "400px",
          width: "400px",
          height: "88vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            height: "91%",
            width: "100%",
          }}
        >
          <TextField
            placeholder=""
            label="Search"
            variant="outlined"
            size="small"
            fullWidth
          />
          <Divider sx={{ my: 2 }} />
          {billingAccounts.length > 0 ? (
            <Box
              sx={{
                height: "90%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {billingAccounts.map((billingAccount) => (
                <Card
                  key={billingAccount._id}
                  variant="outlined"
                  sx={{
                    borderRadius: "10px",
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Grid container columns={6}>
                        <Grid item xs={1}>
                          <Avatar />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1" fontWeight={500}>
                            {billingAccount?.name}
                          </Typography>
                          <Typography variant="body2">
                            {billingAccount?.phone || billingAccount?.email}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          justifyContent={"end"}
                          sx={{
                            display: "flex",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setBillingAccounts((prev) =>
                                prev.filter(
                                  (item) => item._id !== billingAccount._id
                                )
                              );
                            }}
                            size="small"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => setImportIsOpen(true)}
                variant="contained"
                size="small"
                startIcon={<ArrowCircleDown />}
              >
                Billing Accounts
              </Button>
            </Box>
          )}
        </Box>
        {loading && <LinearProgress />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setImportIsOpen(true);
            }}
            variant="outlined"
          >
            Add more
          </Button>
          <DuoButtonGroup
            primaryButtonText={"Import"}
            primaryButtonListener={() => {
              handleImport();
            }}
            secondaryButtonText={"Cancel"}
            hideSecondary={false}
            loadingPrimary={loading}
          />
        </Box>

        <GeneralImportComponent
          title="Billing Accounts"
          isOpen={importIsOpen}
          handleClose={() => {
            setImportIsOpen(false);
          }}
          setIsOpen={setImportIsOpen}
          importConfig={{
            categories: [
              {
                label: "PROJECT",
                icon: <TbCrane size={25} />,
              },
              {
                label: "USER",
                icon: <Person />,
              },
              {
                label: "ORGANIZATION",
                icon: <Business />,
              },
            ],
          }}
          onImport={(data) => {
            const filteredData = [...data, ...billingAccounts];
            setBillingAccounts([...new Set(filteredData)]);
          }}
        />
      </Box>
    </DrawerContainer>
  );
};
